import $ from 'jquery'
import 'slick-carousel'

$(document).ready( initMainSlider )
if (window.acf) {
  window.acf.addAction('render_block_preview/type=slider', initMainSlider)
}

function initMainSlider(){
  if ($('.js-slider').length) {
    console.log('initMainSlider')
    $('.js-slider').slick({
      arrows: true,
      dots: true,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
    });
  }
}
